import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/smiling-senior-woman.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'
import BtnOutlined from 'components/button/outlined-button'
import TelLinkSimple from 'components/tel-link-simple'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const SedationDentistryPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const service_sedation_1 = data.service_sedation_1?.childImageSharp?.fluid
  const service_sedation_2 = data.service_sedation_2?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Sedation Dentistry - The Dentists At Gateway Crossing</title>
      <meta name="description" content="We offer our patients the option of sedation for many of our procedures. Browse the page below to learn more about sedation dentistry. It's easy" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Sedation Dentistry - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="We offer our patients the option of sedation for many of our procedures. Browse the page below to learn more about sedation dentistry. It's easy" />
      <meta property="og:url" content="https://thedentistsatgc.com/services/sedation-dentistry/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="2000" />
      <meta property="og:image:height" content="1328" />
      <meta property="og:image:alt" content="smiling-senior-woman" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Sedation Dentistry - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="We offer our patients the option of sedation for many of our procedures. Browse the page below to learn more about sedation dentistry. It's easy" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
                SEDATION DENTISTRY MCCORDSVILLE
              </p>
              <h1 className="text-light">
              Sedation Dentistry
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer our patients the option of sedation for many of our procedures. Browse the page below to learn more about sedation dentistry. It’s easy to schedule your appointment with us. </p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#pd-section-3"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
              <p className ="section-1-text">SEDATION DENTISTRY</p>
              <h2 className ="section-2-header">Conscious Sedation Provides Comfort</h2>
              <p>Second only to the health of our patients is the comfort of our patients. We understand you may not want to experience your dental procedures due to fear or discomfort. Sedation Dentistry is something that we have made available to all of our patients. Dr. Vogt wants to make sure that you are not afraid or experiencing any pain or discomfort.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Pain & Worry Free</h3>
                <p>If you have had bad experiences in the past that make you uncomfortable with the dentist, don't worry.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">No Bad Memories</h3>
                <p>Most patients remember very little or none of their appointments after being under conscious sedation.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Behavioral Issues Are No Problem</h3>
                <p>Patients with behavioral issues that couldn't normally see the dentist are able to receive care.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Physical Or Mental Handicaps</h3>
                <p>We can treat patients with a physical or mental handicap that would otherwise have a difficult time while under the care of the dentist.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Less Visits</h3>
                <p>Sedation dentistry allows Dr. Vogt to perform more work in less time allowing for multiple procedures to occur at once.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">No Painful Side Effects</h3>
                <p>This type of sedation doesn't require intubation. You won't have to deal with a sore throat, damage to the vocal cords, or any other common side effect from normal sedation.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-6">
              <Img fluid={service_sedation_1} alt="An Elegant Lady With a Beautiful Smile" />
            </div>
            <div className="col-lg-6">
              <Img fluid={service_sedation_2} alt="Lady Holding a Picture While Having a Cup of Coffee" />
            </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="The Dentists at Gateway Crossing"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                DON'T FEAR THE DENTIST!
                </p>
                <h3 className="text-light">
                Don't have another bad experience at the dentist when you don't have to!
                </h3>
                <p className="hero-text text-light">
                Fear of the dentist is common but that shouldn’t be a reason to not get the dental care that you need. Feel no pain and remember nothing. Call TODAY!
                </p>
                <BtnOutlined url="tel:3176439434" class="btn-rounded" label="(317) 643-9434" />
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">SEDATION DENTISTRY DETAILS</p>
              <h2 className ="section-3-header">What is Sedation Dentistry ?</h2>
             <p>Sedation dentistry is a proven clinical approach used to help you get the dental care you need that you might have otherwise skipped due to fear or discomfort.  Almost every procedure uses some type of sedation.</p>
             <p>We have several ways to help our patients avoid any pain during their visit. We can use a combination of oral medication, local numbing shots, nitrous oxide gas to make the experience better.</p>
             <strong><p>Inhaled Minimal Sedation – Nitrous Oxide (laughing Gas) </p></strong>
             <p>You can request to breath nitrous oxide which is mixed with oxygen and delivered to the patient through the use of a mask. Nitrous oxide, often referred to as laughing gas, causes a calm and euphoric feeling. It does not reduce the pain of surgery so local anesthetics are still required. The effect quickly wears off afterwards.</p>
             <strong><p>Oral Sedation Medication</p></strong>
             <p>Oral sedation medication is taken in pill form and can help calm the patient and brings them to a minimal to moderately sedated state. You will still be conscious but it is possible for you to fall asleep. Generally you can be awakened with a gentle shake.</p>
             <strong><p>Local Anesthesia</p></strong>
             <p>The local anesthesia shots are used during any type of surgery or invasive process to numb the teeth and gums completely. Your cheeks and lips may also experience numbness. The effects wear off within a few hours of your appointment. </p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is Sedation Dentistry Right For You?</h3>
                <p>You may have been avoiding the dentist for some time due to a persistent fear or a bad memory. No need to worry, we understand. You’re not alone. Lot’s of people are apprehensive about coming to the dentist for many reasons.</p>
                <p>This is why we offer you the option to be sedated. Aside from the more commonly known local anesthesias and nitrous gas sedations, conscious sedation is an option that helps patients get the care they need.</p>
                <p>Before the procedure starts you are given medications that will help you relax. You may doze in and out of sleep during the procedure and may remember little or none of your time in the office.</p>
                <p>Sedation dentistry might be right for you if you or your children have any of the following:</p>
                  <ul>
                    <li>Moderate to severe fear of the dentist, needles, or injections</li>
                    <li>Special needs like health and behavioral problems</li>
                    <li>Memories of a previous bad experience</li>
                    <li>Little to no response to numbing</li>
                    <li>Intolerable gag reflex</li>
                    <li>Extremely sensitive teeth</li>
                    <li>The need for multiple procedure at once</li>
                    <li>Complicated dental issues</li>
                    <li>Extreme discomfort with dental sounds, odors, and tastes</li>
                    <li>Overwhelming self consciousness about your teeth and gums</li>
                  </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">The Types Of Sedations We Offer</h3>
                <ul>
                  <li>Inhalation sedation AKA Nitrous Oxide (laughing gas)</li>
                  <li>Oral Sedation Medication</li>
                  <li>Local anesthesia (numbing shots)</li>
                  {/* <li> sedation</li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Dental Sedations Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <TelLinkSimple />.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default SedationDentistryPage

export const query = graphql`
  query SedationDentistryPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_sedation_1: file(name: { eq: "sedation_1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_sedation_2: file(name: { eq: "sedation_2" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
